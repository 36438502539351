import axios from 'axios'
import router from '../router/index'
import { Message, MessageBox } from 'element-ui'  //导入element-ui组件库
import common from '@/common/Global.js'
import { getToken, setToken, removeToken } from '@/utils/cookies.js'

// 创建axios的对象
const instance = axios.create({
  baseURL: common.domain,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Cache-Control': 'no-cache'
  }
})

instance.defaults.transformRequest = (data) => {
  return stringify(data)
}

// 请求拦截
// 所有的网络请求都会走这个方法,可以在请求添加自定义内容
instance.interceptors.request.use(
  function (config) {
    config.headers.Authorization = "Bearer " + getToken() // 请求头添加token值
    return config
  },
  function (err) {
    return Promise.request(err)
    // this.$notify({
    //   title: '失败',
    //   message: err,
    //   type: 'error',
    //   duration: 2000
    // })
  }
)

// 响应拦截
// 此处可以根据服务器返回的状态码做相应的数据
instance.interceptors.response.use(
  function (response) {
    const res = response.data
    if (res.status === 500) {
      MessageBox.alert('系统未登录，请重新登录', '错误', {
        confirmButtonText: '确定',
        type: 'error'
      }).then(() => {
        store.dispatch('FedLogOut').then(() => {
          location.reload()
        })
      })
      return Promise.reject('error')
    } else if (res.errno === 502) {
      MessageBox.alert('系统内部错误，请联系管理员维护', '错误', {
        confirmButtonText: '确定',
        type: 'error'
      })
      return Promise.reject('error')
    } else if (res.errno === 401) {
      MessageBox.alert('112233', '错误', {
        confirmButtonText: '确定',
        type: 'error'
      })
      return Promise.reject('error')
    }
    else {
      return res;
    }
  },
  function (err) {
    let response = err.response;
    const status = response.status;
    var token = getToken();
    if (status === 401 && token) {
      removeToken(token);
      MessageBox.alert("登录超时!", "提示", {
        confirmButtonText: "确定",
        callback: action => {
          router.push('/login')
        }
      });
    }
    return Promise.reject('')
  }
)

// 将参数转换成功 formdata 接收格式
function stringify(data) {
  const formData = new FormData()
  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key)) {
      if (data[key]) {
        if (data[key].constructor === Array) {
          if (data[key][0]) {
            if (data[key][0].constructor === Object) {
              formData.append(key, JSON.stringify(data[key]))
            } else {
              data[key].forEach((item, index) => {
                formData.append(key + `[${index}]`, item)
              })
            }
          } else {
            formData.append(key + '[]', '')
          }
        } else if (data[key].constructor === Object) {
          formData.append(key, JSON.stringify(data[key]))
        } else {
          formData.append(key, data[key])
        }
      } else {
        if (data[key] === 0) {
          formData.append(key, 0)
        } else {
          formData.append(key, '')
        }
      }
    }
  }
  return formData
}

// 封装get和post请求
export function get(url, params) {
  return instance.get(url, { params })
}

export function post(url, data) {
  return instance.post(url, data)
}

export default instance;