import Cookies from 'js-cookie'

const TokenKey = 'token'

export function getToken() {
	return Cookies.get(TokenKey)
}

export function setToken(token, json) {
	return Cookies.set(TokenKey, token, json)
}

export function removeToken() {
	return Cookies.remove(TokenKey)
}